<template>
  <div class="flex flex-col">
    <label class="block text-xs font-semibold text-typography-primary font-geist py-[6px]">
      {{ label }}
    </label>

    <div class="flex gap-3">
      <div
        class="h-[44px] w-[44px] rounded border-neutral-300 border"
        :style="{
          backgroundColor: model as string,
        }"
        @click="handleClick()"
      >
        <input type="color" v-model="model" class="opacity-0" ref="colorInput" />
      </div>
      <Input v-model="model" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Input from './Input.vue';
const model = defineModel<any>();
const colorInput = ref<HTMLInputElement>();

defineProps({
  label: {
    default: '',
    type: String,
  },
});

function handleClick() {
  colorInput.value?.click();
}
</script>
